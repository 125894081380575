import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import UqLayoutNarrowHeaderPage from '../components/uqLayoutNarrowHeaderPage';
import SEO from '../components/seo';

export default function RequestDemoSuccess() {
  return (
    <UqLayoutV2>
      <UqAppBarV2>
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className="master-container request-demo-success-page-component">
        <UqLayoutNarrowHeaderPage
          pageInfo={{
            pageName: 'Smash Software Bugs and User Experience Gaps',
            headerTitle: 'Smash Software Bugs and User Experience Gaps',
            headerImageUrl: '/images/request-demo/pink_bug.svg',
          }}
          headerRowClass="request-demo-success"
        >
          <SEO title="Smash Software Bugs and User Experience Gaps" />
          <Container className="text-center">
            <Row className="mt-5">
              <Col className="layout-col">
                <div className="content-header">Thanks for your interest! </div>
              </Col>
            </Row>
            <Row>
              <Col className="layout-col">
                <div className="content mt-3 ml-4 mr-4">
                  Our team will be in touch with you shortly to schedule a demo and
                  offer a free trial.{' '}
                </div>{' '}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="layout-col">
                <img
                  alt="Unitq"
                  src="/images/request-demo/moniker_nue-1.svg"
                  className="w-50p unitq-moniker"
                />
              </Col>
            </Row>
          </Container>
        </UqLayoutNarrowHeaderPage>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
