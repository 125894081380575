import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import NarrowPageHeader from './narrowPageHeader';

const UqLayoutNarrowHeaderPage = ({ children, pageInfo, headerRowClass }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery5 {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Container fluid className="px-0 main">
          <Row
            noGutters
            className={
              classNames(
                'px-0 header-row-background header-row-background-narrow-page justify-content-center',
                { [headerRowClass]: headerRowClass },
              )
            }
          >
            <Col className="page-header-wrapper justify-content-center">
              <NarrowPageHeader
                siteTitle={data.site.siteMetadata.title}
                headerTitle={pageInfo.headerTitle}
                headerImageUrl={pageInfo.headerImageUrl}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Container>
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
);

export default UqLayoutNarrowHeaderPage;
